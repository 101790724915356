import {h, createContext} from 'preact';
import { useState} from "preact/hooks";


export const AppContext = createContext({});

export const AppProvider = ({children}) => {
    const [files, setFiles] = useState({});
    const [status, setStatus] = useState(0);
    const [playing, setPlaying] = useState(false);
    const [currentPlayerAudio, setCurrentPlayingAudio] = useState(null);
    const [currentTabState, setCurrentTabState] = useState(0);
    const [processing, setProcessing] = useState(false);
    const [processLogs, setProcessLogs] = useState([]);

    const pushProcessLog = (message, type = 'info') => {
        setProcessLogs(oldArr => [
            ...oldArr,
            {
                type, message,
                time: new Date().toLocaleTimeString()
            }
        ])
    }

    return (
        <AppContext.Provider
            value={
                {
                    files, status, playing, currentPlayerAudio, currentTabState, processing, processLogs,
                    setFiles, setStatus, setPlaying, setCurrentPlayingAudio, setCurrentTabState, setProcessing,
                    pushProcessLog
                }
            }
        >
            {children}
        </AppContext.Provider>
    )

}
