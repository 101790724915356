import { h } from 'preact';
import { Router } from 'preact-router';
import Home from '../routes/home';
import {AppProvider} from "./../contexts/AppContext";
import BottomBar from "./BottomBar/BottomBar";

const App = () => (
	<div id="app" className="min-h-screen">
		<AppProvider>
			<Router>
				<Home path="/" />
			</Router>
			<BottomBar />
		</AppProvider>
	</div>
)

export default App;
