export const formatSeconds = (input) => {
    if (!input) return '00:00';
    let sec_num = parseInt(input, 10); // don't forget the second param
    let hours   = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    if (hours === '00') {
        return [minutes, seconds].join(':');
    }
    return [hours, minutes, seconds].join(':');
}

export function copyArrayBuffer(src)  {
    let dst = new ArrayBuffer(src.byteLength);
    new Uint8Array(dst).set(new Uint8Array(src));
    return dst;
}

export const getAudioDuration = (arrayBuffer) => {
    const new_buffer = copyArrayBuffer(arrayBuffer);
    return new Promise((resolve) => {
        const audioContext = new AudioContext();
        audioContext.decodeAudioData(new_buffer, (buffer) => {
            resolve(buffer.duration);
        });
    });
}

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
