import {h} from 'preact';
import { useContext, useRef, useEffect, useState } from 'preact/hooks';
import { AppContext } from '../../contexts/AppContext';
import PlayIcon from './../Icons/PlayIcon';
import PauseIcon from './../Icons/PauseIcon';
import {formatSeconds} from "../../helpers/utils";


const BottomBar = () => {
    const audioRef = useRef(null);
    const intervalRef = useRef(null);

    const [progress, setProgress] = useState(0);

    const { setPlaying, playing, currentPlayerAudio, files } = useContext(AppContext);

    const current = currentPlayerAudio !== null ? files[currentPlayerAudio] : null;

    const updateProgress = () => {
        const { duration, currentTime } = audioRef.current;
        setProgress((currentTime / duration) * 100);
    }

    const startTimer = () => {
        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => {
            updateProgress();
        }, [100]);
    }

    useEffect(() => {
        setPlaying(false);
        setProgress(0);
        if (!current) {
            return;
        }
        if(audioRef.current) audioRef.current.pause();
        clearInterval(intervalRef.current);
        if (current.audio) {
            audioRef.current = new Audio(current.audio);
            audioRef.current.addEventListener('ended', () => {
                setPlaying(false);
                setProgress(0);
                audioRef.current.currentTime = 0;
            })
        }
    }, [current]);

    useEffect(() => {
        if(!current || !audioRef.current) {
            if (playing) setPlaying(false);
            return;
        }
        if(playing) {
            audioRef.current.play();
            startTimer();
        }
        else {
            audioRef.current.pause();
            clearInterval(intervalRef.current);
        }
    }, [playing, audioRef.current]);

    const onPlayClicked = () => {
        if (audioRef.current) {
            setPlaying(!playing);
        }
        else if (playing) setPlaying(false);
    }

    const onProgressBarClicked = function (e) {
        if (!audioRef.current) return;
        let rect = e.target.getBoundingClientRect();
        let x = e.clientX - rect.left; //x position within the element.
        const perc = (x / document.body.clientWidth).toFixed(2);
        audioRef.current.currentTime = audioRef.current.duration * perc;
    }



    return (
        <nav className="fixed left-0 right-0 bottom-0 flex flex-col bg-blue-100 text-sm text-blue-900 font-mono">
            <div class="w-full bg-gray-200 h-2.5 dark:bg-gray-700 cursor-pointer" onClick={onProgressBarClicked}>
                <div class="bg-blue-600 h-2.5" style={`width: ${progress.toFixed(2)}%`} />
            </div>
            <div className='flex justify-between items-center'>
                <span className='text-blue-900 text-lg px-2'>{ current ? current.name : 'Ses Dosyası Seçilmedi' }</span>
                <div className='flex items-center'>
                    <span className='mr-5 text-lg'>{ formatSeconds(audioRef.current?.currentTime) } / { formatSeconds(audioRef.current?.duration) } </span>
                    <button onClick={onPlayClicked}>
                        {
                            playing ?
                                <PauseIcon width={64} /> :
                                <PlayIcon width={64} />
                        }
                    </button>
                </div>
            </div>
        </nav>
    )
}
export default BottomBar;
